import { useEffect, useMemo, useState } from 'react';
import { useFlag } from '@unleash/proxy-client-react';
import { getActiveIds, getEntity } from '@ngneat/elf-entities';
import { pipe, tap } from 'rxjs';
import { get, includes, isEqual } from 'lodash';

import { FeatureFlag } from '@revelio/core';
import { useGetLoggedInUser, useIsRevelioAdmin } from '@revelio/auth';
import { CustomRoleTaxonomySelection } from '@revelio/data-access';

import {
  addActiveFiltersIds,
  clearRoleCacheFilters,
  deleteFilters,
  upsertFilter,
  upsertFiltersWithProvidedValue,
} from '../filters.repository';
import { ROLE_GRANULARITY_FILTERS } from '../filters.constants';
import {
  FilterItem,
  FilterList,
  OtherFilterNames,
  SelectFilter,
  SelectionCategories,
  SubFilterNames,
} from '../filters.model';
import { ViewTypes } from '../../data-api/data-api.model';
import { filterStore } from '../filters.core';
import { useSingleOrMoreFilterState } from '../filters.engine';
import { getAdaptiveRoleTaxonomyId } from './get-adaptive-role-taxonomy-id';
import { useSearchParams } from 'react-router-dom';
import { parse } from 'query-string';
import { SearchParamFilters } from '../filters.deepLinks';

export type RoleTaxonomySettingOption = {
  label: string;
  value: RoleTaxonomySettings;
};

export enum RoleTaxonomySettings {
  DISABLED = 'disabled',
  GENERIC = 'generic',
  ADAPTIVE = 'adaptive',
}

const CUSTOM_ROLE_TAXONOMY_STORAGE_KEY = 'custom_role_taxonomy';
const disabledOption: RoleTaxonomySettingOption = {
  label: 'Disabled',
  value: RoleTaxonomySettings.DISABLED,
};
const genericOption: RoleTaxonomySettingOption = {
  label: 'Generic',
  value: RoleTaxonomySettings.GENERIC,
};
const CustomRoleTaxonomySelectOptions: RoleTaxonomySettingOption[] = [
  disabledOption,
  genericOption,
  { label: 'Adaptive', value: RoleTaxonomySettings.ADAPTIVE },
];

export const useRoleTaxonomySetting = () => {
  const isCustomTaxonomyFeatureFlagEnabled = useFlag(
    FeatureFlag.CustomRoleTaxonomy
  );
  const { isRevelioAdmin, fetching } = useIsRevelioAdmin();
  const [selectedOption, setSelectedOption] =
    useState<RoleTaxonomySettingOption>(
      getRoleTaxonomyStorage() ?? disabledOption
    );
  const { loggedInUser } = useGetLoggedInUser();

  const isEnabled = useMemo(
    () =>
      isCustomTaxonomyFeatureFlagEnabled &&
      (isRevelioAdmin || loggedInUser?.email === 'isar.gangwani@gmail.com'),
    [isCustomTaxonomyFeatureFlagEnabled, isRevelioAdmin, loggedInUser?.email]
  );

  useEffect(() => {
    if (fetching) return;
    const roleTaxonomyOption = getRoleTaxonomyStorage();

    if (!isEnabled && roleTaxonomyOption?.value !== disabledOption.value) {
      clearRoleCacheFilters();
      deleteFilters([...ROLE_GRANULARITY_FILTERS, SubFilterNames.SUB_ROLE]);
      setRoleTaxonomyStorage(disabledOption);
    }

    if (isEnabled && !roleTaxonomyOption) {
      setRoleTaxonomyStorage(disabledOption);
    }
  }, [isEnabled, fetching]);

  // Logging Taxonomy Settings to assist in debugging issues while we transition to new endpoint
  useEffect(() => {
    logSetting({
      featureFlag: isEnabled,
      option: getRoleTaxonomyStorage()?.label,
    });
  }, [isEnabled, selectedOption]);

  return {
    isFeatureFlagEnabled: isEnabled,
    isEnabled:
      isEnabled && selectedOption.value !== RoleTaxonomySettings.DISABLED,
    value: isEnabled ? selectedOption.value : null,
    selectedOption,
    options: CustomRoleTaxonomySelectOptions,
    setOption: (option: RoleTaxonomySettingOption) => {
      setRoleTaxonomyStorage(option);
      setSelectedOption(option);
      clearRoleCacheFilters();
    },
  };
};

const getRoleTaxonomyStorage = () => {
  const storedValue = localStorage.getItem(CUSTOM_ROLE_TAXONOMY_STORAGE_KEY);
  if (!storedValue) return null;
  return (
    CustomRoleTaxonomySelectOptions.find((o) => o.value === storedValue) ||
    disabledOption
  );
};

const setRoleTaxonomyStorage = (option: RoleTaxonomySettingOption) => {
  localStorage.setItem(CUSTOM_ROLE_TAXONOMY_STORAGE_KEY, option.value);
};

const logSetting = (() => {
  let lastLog = '';

  return (value: Record<string, any>) => {
    const currentLog = JSON.stringify(value);
    if (currentLog !== lastLog) {
      console.log('Custom Role Taxonomy: Settings: ', value);
      lastLog = currentLog;
    }
  };
})();

export const useAdaptiveRoleTaxonomy = ({
  viewType,
  primaryFilters,
}: {
  viewType: ViewTypes;
  primaryFilters: SelectionCategories[];
}) => {
  const { isEnabled: isCustomRoleTaxonomyEnabled, value } =
    useRoleTaxonomySetting();

  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (viewType !== ViewTypes.COMPANY) {
      return;
    }

    const activeIds = filterStore.query(getActiveIds);
    if (
      isCustomRoleTaxonomyEnabled &&
      !includes(activeIds, OtherFilterNames.ROLE_TAXONOMY)
    ) {
      const customTaxonomyFilter = filterStore.query(
        getEntity(OtherFilterNames.ROLE_TAXONOMY)
      );
      const deepLinkFilters = parse(searchParams?.toString(), {
        arrayFormat: 'comma',
      }) as SearchParamFilters;
      const roleTaxonomyDeepLink = get(
        deepLinkFilters,
        OtherFilterNames.ROLE_TAXONOMY,
        false
      );
      if (!customTaxonomyFilter && !roleTaxonomyDeepLink) {
        upsertFiltersWithProvidedValue(
          {
            [OtherFilterNames.ROLE_TAXONOMY]: {
              value: {
                default: true,
              },
            },
          },
          true
        );
      } else {
        addActiveFiltersIds([OtherFilterNames.ROLE_TAXONOMY]);
      }
    }
  }, [isCustomRoleTaxonomyEnabled, viewType, searchParams]);

  useSingleOrMoreFilterState<SelectFilter<string>, SelectFilter<FilterList>[]>(
    [
      OtherFilterNames.ROLE_TAXONOMY,
      ...primaryFilters,
      ...ROLE_GRANULARITY_FILTERS,
    ],
    pipe(
      tap((filters) => {
        if (viewType !== ViewTypes.COMPANY || !isCustomRoleTaxonomyEnabled) {
          return;
        }

        // when role filter in particular taxonomy set, we should not use a different adaptive taxonomy
        const alreadyUsingRoleTaxonomy = (
          filters as SelectFilter<FilterList>[]
        ).filter((item) => includes(ROLE_GRANULARITY_FILTERS, item.id));
        if (alreadyUsingRoleTaxonomy.length) {
          return;
        }

        const usingAdaptiveRoleTaxonomy =
          value === RoleTaxonomySettings.ADAPTIVE;
        const currentCustomTaxonomyFilter = (
          filters as SelectFilter<FilterItem>[]
        ).find((item) => item.id === OtherFilterNames.ROLE_TAXONOMY);
        const primaryFilters = (filters as SelectFilter<FilterList>[]).filter(
          (item) =>
            includes(
              [SelectionCategories.COMPANY, SelectionCategories.INDUSTRY],
              item.id
            )
        );
        // only calculate the adaptive role taxonomy if there are primary filters set
        // without primary filters, will initially load default which flickers deep link roles
        if (usingAdaptiveRoleTaxonomy && primaryFilters.length) {
          const calculatedCustomRoleAdaptiveTaxonomy =
            getAdaptiveRoleTaxonomyId(primaryFilters);
          if (
            !isEqual(
              currentCustomTaxonomyFilter?.value,
              calculatedCustomRoleAdaptiveTaxonomy
            )
          ) {
            clearRoleCacheFilters();
            upsertFilter(OtherFilterNames.ROLE_TAXONOMY, {
              value: calculatedCustomRoleAdaptiveTaxonomy,
            });
          }
        } else {
          if (
            !(currentCustomTaxonomyFilter?.value as CustomRoleTaxonomySelection)
              .default &&
            value === RoleTaxonomySettings.GENERIC
          ) {
            clearRoleCacheFilters();
            upsertFilter(OtherFilterNames.ROLE_TAXONOMY, {
              value: { default: true },
            });
          }
        }
      })
    )
  );
};
