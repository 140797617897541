import { Box, FormControl, FormLabel, Text, useToast } from '@chakra-ui/react';
import { Select, SingleValue } from 'chakra-react-select';
import {
  clearRoleCacheFilters,
  RoleTaxonomySettingOption,
  useRoleTaxonomySetting,
} from '@revelio/filtering';

export const AdminUserCustomRoleTaxonomySelect = () => {
  const { isFeatureFlagEnabled, selectedOption, options, setOption } =
    useRoleTaxonomySetting();
  const toast = useToast();

  const handleOption = (option: SingleValue<RoleTaxonomySettingOption>) => {
    setOption(option as RoleTaxonomySettingOption);
    clearRoleCacheFilters();
    toast({
      title: 'Taxonomy set on this browser',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    isFeatureFlagEnabled && (
      <FormControl id="custom_role_taxonomy">
        <FormLabel fontSize="sm" fontWeight="semibold">
          Custom Role Taxonomy Setting
        </FormLabel>

        <Box>
          <Select
            value={selectedOption}
            options={options}
            isLoading={false}
            placeholder="Select role taxonomy setting"
            onChange={handleOption}
          />
        </Box>
        <Text fontSize="sm" color="gray.600">
          Role taxonomy setting only affects this browser
        </Text>
      </FormControl>
    )
  );
};
