import { graphql } from '../gql';

export const GET_CLIENTS_SERVER = graphql(`
  query GetClientDetailsServer($input: [String!]) {
    clients(ids: $input) {
      client_name
      users {
        id
        email
      }
    }
  }
`);

export const UPDATE_USER_SERVER = graphql(`
  mutation UpdateUserServer($input: UserRequest!) {
    updateUser(input: $input) {
      id
    }
  }
`);

export const TALENT_DISCOVERY_GET_MAP_STATS = graphql(`
  query GetTalentDiscoveryMapStats(
    $company: [ID!]
    $msa: [ID!]
    $country: [ID!]
    $job_category: [ID!]
    $role_k150: [ID!]
    $role_k1500: [ID!]
    $seniority: [ID!]
    $ethnicity: [ID!]
    $start_date: String
    $end_date: String
    $posting_start_date: String
    $posting_end_date: String
    $provider: [Int!]
    $metric_mode: String
    $dim1: Dimension1
  ) {
    composition(
      filters: {
        company: $company
        msa: $msa
        country: $country
        job_category: $job_category
        role_k150: $role_k150
        role_k1500: $role_k1500
        seniority: $seniority
        ethnicity: $ethnicity
        start_date: $start_date
        end_date: $end_date
      }
      dim1: $dim1
    ) {
      metadata {
        id
        shortName
        longName
      }
      metrics {
        growth_rate {
          timeseries {
            id
            date
            value
          }
        }
        headcount {
          timeseries {
            id
            date
            value
          }
        }
      }
    }
    rawComposition: composition(
      filters: {
        company: $company
        msa: $msa
        country: $country
        job_category: $job_category
        role_k150: $role_k150
        role_k1500: $role_k1500
        seniority: $seniority
        ethnicity: $ethnicity
        raw_metrics: true
        start_date: $start_date
        end_date: $end_date
      }
      dim1: $dim1
    ) {
      metadata {
        id
        shortName
        longName
      }
      metrics {
        growth_rate {
          timeseries {
            id
            date
            value
          }
        }
        headcount {
          timeseries {
            id
            date
            value
          }
        }
      }
    }
    posting(
      filters: {
        company: $company
        msa: $msa
        country: $country
        job_category: $job_category
        role_k150: $role_k150
        role_k1500: $role_k1500
        seniority: $seniority
        provider: $provider
        start_date: $posting_start_date
        end_date: $posting_end_date
        metric_mode: $metric_mode
      }
      dim1: $dim1
      dim2: date
    ) {
      metadata {
        id
        shortName
        longName
      }
      category {
        metadata {
          shortName
        }
        metrics {
          time_to_fill
          active
        }
      }
    }
  }
`);

export const USER_SIMPLE_DETAILS_QUERY = graphql(`
  query GetUserSimpleDetails($ids: [String]) {
    users(ids: $ids) {
      id
      active
      name
      username
      role
    }
  }
`);

export const USER_RESUMES_QUERY = graphql(`
  query GetUserResumes($ids: [String]) {
    users(ids: $ids) {
      id
      resumes {
        resume_id
        name
        enriched_data
        download_link
      }
    }
  }
`);

export const USER_DETAILS_QUERY = graphql(`
  query GetUserDetails($ids: [String]) {
    users(ids: $ids) {
      id
      name
      username
      email
      role
      client_name
      live
      active
      tabs
      company_lists
      linkup_postings
      unified_postings
      num_seats
      metadata {
        key
        value
      }
    }
  }
`);
