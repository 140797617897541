/* eslint-disable */

import { Box, Flex, Grid, GridItem, Text } from '@chakra-ui/layout';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { data } from './data/allSalaries';
import { PrizeType } from './utils';
import { usePlayer } from './context/PlayerContext';

import slotsOverlay from './images/slots-overlay.png';
import { useKeyPress } from 'react-use';

const importAllImages = (r: any) => {
  const images: Record<string, any> = {};
  r.keys().forEach((key: any) => {
    images[key.replace('./', '') as string] = r(key);
  });
  return images;
};

const seniorityImages = importAllImages(
  (require as any).context('./images/seniority', false, /\.(png)$/)
);

const roleImages = importAllImages(
  (require as any).context('./images/role', false, /\.(png)$/)
);

const msaImages = importAllImages(
  (require as any).context('./images/msa', false, /\.(png)$/)
);

interface ReelsV5Props {
  isPressed: boolean;
  isSpinning: boolean;
  setIsSpinning: (isSpinning: boolean) => void;
  autoPlay: boolean;
  autoPlayRef: React.MutableRefObject<boolean>;
  isOpen: boolean;
  onClose: () => void;
  handleCompletedSpins: any;
  isOpenStart: boolean;
  onOpenStart: () => void;
  onCloseStart: () => void;
  play2: any;
  playSelection: any;
  playSelection2: any;
  playSelection3: any;
  getPrizeLevel: () => PrizeType;
  onCloseWinner: () => void;
  isResultsOpen: boolean;
  onResultsClose: () => void;
  setWinnerCompleted: (isCompleted: boolean) => void;
  isOpenWinner: boolean;
  isEscPressed: boolean;
  reelSound: any;
}

const ReelsV5 = ({
  isPressed,
  isSpinning,
  setIsSpinning,
  autoPlay,
  autoPlayRef,
  isOpen,
  onClose,
  handleCompletedSpins,
  isOpenStart,
  onOpenStart,
  onCloseStart,
  play2,
  playSelection,
  playSelection2,
  playSelection3,
  getPrizeLevel,
  onCloseWinner,
  isResultsOpen,
  onResultsClose,
  setWinnerCompleted,
  isOpenWinner,
  isEscPressed,
  reelSound,
}: ReelsV5Props) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [isAnimating2, setIsAnimating2] = useState(false);
  const [isAnimating3, setIsAnimating3] = useState(false);

  const [grandPrizeSalaries, setGrandPrizeSalaries] = useState<any[]>([]);
  const [megaPrizeSalaries, setMegaPrizeSalaries] = useState<any[]>([]);
  const [ultimatePrizeSalaries, setUltimatePrizeSalaries] = useState<any[]>([]);
  const [basicPrizeSalaries, setBasicPrizeSalaries] = useState<any[]>([]);
  const [prizeLevelWinner, setPrizeLevelWinner] = useState<PrizeType | null>(
    PrizeType.NONE
  );

  const [seniority, setSeniority] = useState([
    1, 2, 3, 4, 5, 6, 7, 4, 1, 7, 5, 2,
  ]);

  const [seniorityShortNames, setSeniorityShortName] = useState<string[]>([]);

  const [role, setRole] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 60, 31]);

  const [roleShortNames, setRoleShortNames] = useState<string[]>([]);

  const [msa, setMsa] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 29, 15, 110]);

  const [msaShortNames, setMsaShortNames] = useState<string[]>([]);

  const [salary, setSalary] = useState<number[]>([]);

  const { player } = usePlayer();

  //const [isESCPressed] = useKeyPress('Escape');

  const stopAnimation = () => {
    if (isAnimating) {
      setIsAnimating(false);
      playSelection();
    }
    if (!isAnimating && isAnimating2) {
      setIsAnimating2(false);
      playSelection2();
    }
    if (!isAnimating && !isAnimating2 && isAnimating3) {
      setIsAnimating3(false);
      playSelection3();
      handleCompletedSpins({
        seniority: seniority.slice(-2, -1)[0],
        seniorityShortNames: seniorityShortNames.slice(-2, -1)[0],
        role: role.slice(-2, -1)[0],
        roleShortNames: roleShortNames.slice(-2, -1)[0],
        msa: msa.slice(-2, -1)[0],
        msaShortNames: msaShortNames.slice(-2, -1)[0],
        salary: salary.slice(-2, -1)[0],
        winner: prizeLevelWinner,
      });
    }
    // if (!isAnimating && !isAnimating2 && !isAnimating3) {
    // startAnimation();
    // setIsSpinning(false);
    // }
  };

  const startAnimation = () => {
    onCloseWinner();

    if (player.email === '' && player.playerId === '') {
      onOpenStart();
    } else {
      play2();
      if (!isAnimating && !isAnimating2 && !isAnimating3) {
        // getNewSlots();
        const prizeLevel = getPrizeLevel();
        setPrizeLevelWinner(prizeLevel);
        const { seniority, role, msa, salary } =
          getRandomCombinations(prizeLevel);
        // const prizeLevel = getPrizeLevel();
        // const prizeData = getPrizeLevelData(prizeLevel);

        // if (playerEmail === '') {
        //   onOpenStart();
        // } else {

        setIsAnimating(true);
        setSeniority(seniority.id);
        setSeniorityShortName(seniority.shortName);
        setIsAnimating2(true);
        setRole(role.id);
        setRoleShortNames(role.shortName);
        setIsAnimating3(true);
        setMsa(msa.id);
        setMsaShortNames(msa.shortName);
        setSalary(salary);
      }
      //Staggered Animation works but needs to be fixed to disallow instant stop before all animations are done
      // for (let i = 0; i < 3; i++) {
      //   setTimeout(() => {
      //     if (i === 0) {
      //       setIsAnimating(true);
      //       setSeniority(seniority);
      //     } else if (i === 1) {
      //       setIsAnimating2(true);
      //       setRole(role);
      //     } else {
      //       setIsAnimating3(true);
      //       setMsa(msa);
      //     }
      //   }, i * 300);
      // }
      // }
    }
  };

  // Handle Space Bar / Red Button Press
  useEffect(() => {
    if (isPressed && (!isAnimating || !isAnimating2 || !isAnimating3)) {
      if (isOpen && !isOpenWinner) {
        onClose();
        startAnimation();
        setIsSpinning(true);
        // playSpinButton();
        // buttonSound.fade(0.75, 0, 350);
      } else if (isOpenWinner) {
        onCloseWinner();
        // playSpinButton();
        // buttonSound.fade(0.75, 0, 350);
      } else if (isOpenStart) {
        // if (player.email === '') {
        //   setPlayer({ email: 'Player1234' });
        // }
        onCloseStart();
        startAnimation();
        setIsSpinning(true);
      } else {
        // playSpinButton();
        // buttonSound.fade(0.75, 0, 350);
        // play2();
        // reelSound.fade(0.5, 0.2, 6000);
        // spinAll();
        startAnimation();
        setIsSpinning(true);
        setWinnerCompleted(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPressed, autoPlay, autoPlayRef.current, player.email, player.playerId]);

  useEffect(() => {
    if (isPressed && (isAnimating || isAnimating2 || isAnimating3)) {
      stopAnimation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPressed]);

  useEffect(() => {
    onCloseStart();
  }, [player.email]);

  const generateSalaryLevelData = () => {
    const basicData = data.filter((d) => d.salary < 150000);
    const ultimateData =
      data.filter((d) => d.salary >= 150000 && d.salary <= 250000) || [];
    const megaData =
      data.filter((d) => d.salary >= 250000 && d.salary <= 500000) || [];
    const grandData = data.filter((d) => d.salary >= 500000) || [];

    setGrandPrizeSalaries(grandData);
    setMegaPrizeSalaries(megaData);
    setUltimatePrizeSalaries(ultimateData);
    setBasicPrizeSalaries(basicData);

    // console.log('basicData', basicData.length);
    // console.log('ultimateData', ultimateData.length);
    // console.log('megaData', megaData.length);
    // console.log('grandData', grandData.length);
  };

  // onLoad Stuff
  useEffect(() => {
    generateSalaryLevelData();
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getRandom = (obj: any) => {
    const randomIndex = Math.floor(Math.random() * obj?.length);
    return obj?.[randomIndex];
  };

  const getRandomCombinations = (prize: PrizeType) => {
    const randomBasicSlots = Array.from({ length: 12 }, () =>
      getRandom(basicPrizeSalaries)
    );
    if (prize === PrizeType.GRAND) {
      const randomGrandSlots = Array.from({ length: 1 }, () =>
        getRandom(grandPrizeSalaries)
      );
      randomBasicSlots[randomBasicSlots.length - 2] = randomGrandSlots[0];
    } else if (prize === PrizeType.MEGA) {
      const randomMegaSlots = Array.from({ length: 1 }, () =>
        getRandom(megaPrizeSalaries)
      );
      randomBasicSlots[randomBasicSlots.length - 2] = randomMegaSlots[0];
    } else if (prize === PrizeType.ULTIMATE) {
      const randomUltimateSlots = Array.from({ length: 1 }, () =>
        getRandom(ultimatePrizeSalaries)
      );
      randomBasicSlots[randomBasicSlots.length - 2] = randomUltimateSlots[0];
    }

    // const seniority = randomBasicSlots.map((d) => d?.seniority);
    // const role = randomBasicSlots.map((d) => d?.role);
    // const msa = randomBasicSlots.map(
    //   (d: { msa: string; msaId: keyof typeof stateData }) =>
    //     startCase(d?.msa) +
    //     (stateData[d.msaId] ? ',' : '') +
    //     (stateData[d.msaId] ? stateData[d.msaId] : '')
    // );
    const seniority = {
      id: randomBasicSlots.map((d) => d?.seniorityId),
      shortName: randomBasicSlots.map((d) => d?.seniority),
    };
    const role = {
      id: randomBasicSlots.map((d) => d?.roleId),
      shortName: randomBasicSlots.map((d) => d?.role),
    };
    const msa = {
      id: randomBasicSlots.map((d) => d?.msaId),
      shortName: randomBasicSlots.map((d) => d?.msa),
    };

    // const seniority = getRandomItem(seniority);
    // const role = getRandomItem(rolek150);
    // const msa = getRandomItem(msa);
    // return { seniority, role, msa };
    return {
      seniority,
      role,
      msa,
      salary: randomBasicSlots.map((d) => d?.salary),
    };
  };

  // console.log('test', getRandomCombinations());

  // const getNewSlots = () => {
  //   const { seniority, role, msa } = getRandomCombinations();
  //   setSeniority(seniority);
  //   setRole(role);
  //   setMsa(msa);
  // };

  const resetReels = () => {
    setIsAnimating(false);
    setIsAnimating2(false);
    setIsAnimating3(false);
    setIsSpinning(false);
    reelSound.stop();
  };

  useEffect(() => {
    if (isEscPressed) {
      resetReels();
    }
  }, [isEscPressed]);
  return (
    <Grid
      zIndex={1}
      templateColumns="repeat(3, 1fr)"
      gap={0}
      color="white"
      w="full"
      h="full"
      alignItems="center"
      justifyItems="center"
      // border={1}
      // borderColor="white"
      // borderStyle="solid"
      overflow="hidden"
      position="relative"
    >
      <img
        src={slotsOverlay}
        alt=""
        style={{
          position: 'absolute',
          zIndex: 101,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%',
          padding: '6px 10px',
          objectFit: 'fill',
        }}
      />
      <GridItem
        h="full"
        w="full"
        display="flex"
        overflow="hidden"
        justifyContent="center"
        // alignItems="center"
        // backgroundColor="rgba(10, 0, 150, 1)"
        background="linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(2,0,36,1) 15%, rgba(11,9,96,1) 50%, rgba(2,0,36,1) 85%, rgba(0,0,0,1) 100%)"
        borderLeft="6px solid #FFCC18"
        borderY="6px solid #FFCC18"
      >
        <Box
          className={`carousel-content ${isAnimating ? 'animating' : 'stopped'} `}
        >
          {[...seniority, ...seniority].map((d, index) => (
            <Box
              key={index}
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="33%"
              // borderY="4px double #FFCC18"
            >
              <img
                // src={`./s${d}.png`}
                src={seniorityImages[`s${d}.png`]}
                alt={`${d}`}
                style={{ pointerEvents: 'none', userSelect: 'none' }}
              />
            </Box>
          ))}
        </Box>
      </GridItem>
      <GridItem
        h="full"
        w="full"
        display="flex"
        overflow="hidden"
        justifyContent="center"
        background="linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(2,0,36,1) 15%, rgba(11,9,96,1) 50%, rgba(2,0,36,1) 85%, rgba(0,0,0,1) 100%)"
        // borderLeft="6px solid #FFCC18"
        borderY="6px solid #FFCC18"
      >
        <div
          className={`carousel-content2 ${isAnimating2 ? 'animating' : 'stopped'} `}
        >
          {[...role, ...role].map((d, index) => (
            <Box
              key={index}
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="33%"
              // borderY="4px ridge #FFCC18"
            >
              <img
                // src={`./r${d}.png`}
                src={roleImages[`r${d}.png`]}
                alt={`${d}`}
                style={{ pointerEvents: 'none', userSelect: 'none' }}
              />
            </Box>
          ))}
        </div>
      </GridItem>
      <GridItem
        h="full"
        w="full"
        display="flex"
        overflow="hidden"
        justifyContent="center"
        background="linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(2,0,36,1) 15%, rgba(11,9,96,1) 50%, rgba(2,0,36,1) 85%, rgba(0,0,0,1) 100%)"
        // borderLeft="6px solid #FFCC18"
        borderRight="6px solid #FFCC18"
        borderY="6px solid #FFCC18"
      >
        <div
          className={`carousel-content3 ${isAnimating3 ? 'animating' : 'stopped'} `}
        >
          {[...msa, ...msa].map((d, index) => (
            <Box
              key={index}
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="33%"
              // borderY="4px double #FFCC18"
            >
              <img
                // src={`./m${d}.png`}
                src={msaImages[`m${d}.png`]}
                alt={`${d}`}
                style={{ pointerEvents: 'none', userSelect: 'none' }}
              />
            </Box>
          ))}
        </div>
      </GridItem>
    </Grid>
  );
};

export default ReelsV5;
